@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "helpers/mixins";
@import "helpers/_medias.scss";
@import "_fontello.scss";
@import "components/breadcrumb";
@import "base/_grid.scss"; 

div, span, section, article{
    &.background-transparent {
        background-color: transparent !important;
    }

    @include medium-large {
        &.background-md-transparent {
            background-color: transparent !important;
        }
    }
}

html {
    overflow: hidden;
}

body {
    overflow-y: overlay;
    overflow-x: hidden;
}

.img-fluid {
    height: auto !important;
    width: 100% !important;
}

.width-p-38p {
    width: 38%;
}

.width-p-33 {
    width: 33%;
}

.background-black {
    background: #000;
}

.stop-line-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.centerVertHoriz {
    @include centerVertHoriz;
}

@include medium-large {
    .centerVertHoriz-md {
        @include centerVertHoriz;
    }
    .centerVertHoriz_important-md {
        @include centerVertHoriz_important;
    }
}

.centerVert {
    @include centerVert;
}

.border-unset {
    border: unset !important;
}

.min-width-40 {
    min-width: 40px;
}
                             
.text-transform-uppercase {
    text-transform: uppercase;
}

.text-transform-none {
    text-transform: none;
}

.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

.object-position-center {
    object-position: center;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.color-linhaClass {
    color: #00331f;
}

.background-linhaClass {
    background-color: #00331f;
}

.linhaClassTopo {
    position: absolute;
    top: -2px;
}

.width-fit-content {
    width: fit-content;
}

.height-fit-content {
    height: fit-content;
}

.pointer-events-none {
    pointer-events: none;
}

.margin-left-md-auto {
    @include medium-large {
        margin-left: auto;
    }
}                                               

.sliderVitrineImoveis {
    margin-bottom: -2px;

    ul.slick-dots {
        @include centerVertHoriz;
    }
}

.display-none-import, article.display-none-import {
    display: none !important;
}

.slider, .slider-dot {
    .icon-Play {
        display: flex;
        align-items: center;
        justify-content: center;
        @include centerVertHoriz;
    }

    .slick-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        li.slick-active {
            opacity: 0.48;
        }

        li {
            background: #242625;
            @include circulo(8px);
            margin: 0 2px;

            @include medium-large {
                @include circulo(14px);
            }

            button {
                color: transparent;
                background: transparent;
            }
        }
    }

    .slick-slide {
        height: 100%;
    }
}

.height-md-80vh {
    @include medium-large {
        height: 80vh !important;
    }
}

.slider, .slider-galeria {
    .slick-prev {
        top: 130px;
        left: 10px;
        font-size: 20px;
        transform: unset;

        &:before {
            content: "\E814";
            @include fonte-icone-new;
        }
    }

    .slick-next {
        font-size: 20px;
        top: 130px;
        left: unset;
        margin: 0;
        right: -30px;

        &:before {
            content: "\E852";
            @include fonte-icone-new;
        }
    }
}

.videoYoutubeHome {
    iframe {
        width: 100%;
        border-radius: 16px;
        height: 185px;

        @include medium-large {
            height: 440px;
        }
    }
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.pg-ligamospravoce .form-label input[type="checkbox"] + label.checkedJs:after,
.form-label input[type="radio"] + label.checkedJs:after {
    opacity: 0 !important;
}

.pg-ligamospravoce .form-label input[type="checkbox"] + label:after,
.form-label input[type="radio"] + label:after {
    opacity: 1 !important;
}

.form-label input[type="checkbox"] + label.checkedJs:after,
.form-label input[type="radio"] + label.checkedJs:after {
    opacity: 0 !important;
}

.form-label input[type="checkbox"] + label:after,
.form-label input[type="radio"] + label:after {
    opacity: 1 !important;
}

.pg-ligamospravoce .btn {
    text-transform: none;
    font-size: 0.875rem;
}

.accordion {

    .tab-content,
    .tab-sub-content {
        display: none;
    }

    .tab-title {
        cursor: pointer;
    }

    .tab-title.active {

        i {
            animation-delay: 2s;
            transform: rotate(180deg);
        }
    }
}

.pg-detalhe-imovel .localizacao-imovel .content.aerea a, .pg-detalhe-imovel .localizacao-imovel .content.ilustrativo a {
    background-size: cover !important;
}

.tamanho__card {
    height: 310px !important;
}

.ver-mais-perguntas {
    display: none;
}

.altura-card-financiamento {
    height: 360px;
}

.icon-Coworking {
    font-size: 35px !important;
    position: relative;
    top: -16px;
    left: -6px;
}

.ajuste-vitrine-mobile {
    height: 360px;
    width: 100%;
}

.cc-banner {
    border: 1px solid;
}

@media(max-width:768px) {
    .cc-banner {
        margin-bottom: 64px;
    }
}

@media(min-width: 992px) {
    .estilo-cards-imoveis {
        display: grid !important;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
    }
}