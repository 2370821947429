﻿@font-face {
  font-family: "icone-new";
  src: url("../../../wwwroot/build/font/icons.eot");
  src: url("../../../wwwroot/build/font/icons.eot?09032022#iefix")
      format("embedded-opentype"),
    url("../../../wwwroot/build/font/icons.woff2?09032022") format("woff2"),
    url("../../../wwwroot/build/font/icons.woff?09032022") format("woff"),
    url("../../../wwwroot/build/font/icons.ttf?09032022") format("truetype"),
    url("../../../wwwroot/build/font/icons.svg?09032022#mrv-icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.fontIcons:before {
  font-family: "icone-new" !important;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  width: auto !important;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-Telefone_agenda:before { content: '\e800'; } /* '' */
.icon-Desconto:before { content: '\e801'; } /* '' */
.icon-Megafone:before { content: '\e802'; } /* '' */
.icon-Tela-chat:before { content: '\e803'; } /* '' */
.icon-Mao_com_cifrao:before { content: '\e804'; } /* '' */
.icon-Chat-1:before { content: '\e805'; } /* '' */
.icon-Play:before { content: '\e806'; } /* '' */
.icon-Banco:before { content: '\e807'; } /* '' */
.icon-Dinheiro_mao:before { content: '\e808'; } /* '' */
.icon-Dinheiro_bolsa:before { content: '\e809'; } /* '' */
.icon-Selo:before { content: '\e80a'; } /* '' */
.icon-Caderneta:before { content: '\e80b'; } /* '' */
.icon-Relogio:before { content: '\e80c'; } /* '' */
.icon-Baixo:before { content: '\e80d'; } /* '' */
.icon-Whatsapp:before { content: '\e80e'; } /* '' */
.icon-Chave_mao:before { content: '\e80f'; } /* '' */
.icon-Afirmativo:before { content: '\e810'; } /* '' */
.icon-Linha_especial_de_loucas_e_metais:before { content: '\e811'; } /* '' */
.icon-Varanda:before { content: '\e812'; } /* '' */
.icon-Bicicletario:before { content: '\e813'; } /* '' */
.icon-Seta-esquerda:before { content: '\e814'; } /* '' */
.icon-Dinheiro_up:before { content: '\e815'; } /* '' */
.icon-Seta_lado_direto:before { content: '\e816'; } /* '' */
.icon-CVA:before { content: '\e817'; } /* '' */
.icon-Dinheiro_maos:before { content: '\e818'; } /* '' */
.icon-Carta:before { content: '\e819'; } /* '' */
.icon-Filtro:before { content: '\e81a'; } /* '' */
.icon-Telefone:before { content: '\e81b'; } /* '' */
.icon-Casa_com_estrelas:before { content: '\e81c'; } /* '' */
.icon-Simbolo_seguranca:before { content: '\e81d'; } /* '' */
.icon-Localizacao_large:before { content: '\e81e'; } /* '' */
.icon-Mapa:before { content: '\e81f'; } /* '' */
.icon-Diarista:before { content: '\e820'; } /* '' */
.icon-Casa_estrela:before { content: '\e821'; } /* '' */
.icon-Espaco_pizza:before { content: '\e822'; } /* '' */
.icon-Play_video:before { content: '\e823'; } /* '' */
.icon-Garagem:before { content: '\e824'; } /* '' */
.icon-Tela:before { content: '\e825'; } /* '' */
.icon-Tour:before { content: '\e826'; } /* '' */
.icon-Ler_mais:before { content: '\e827'; } /* '' */
.icon-Olho_aberto:before { content: '\e828'; } /* '' */
.icon-Acabamentos_exclusivita:before { content: '\e82d'; } /* '' */
.icon-Sistema_de_vendas:before { content: '\e82e'; } /* '' */
.icon-Contrato_check:before { content: '\e82f'; } /* '' */
.icon-Sistema_de_seguranca_BIO-1:before { content: '\e831'; } /* '' */
.icon-Sistema_de_seguranca-2:before { content: '\e833'; } /* '' */
.icon-Pin_localizacao:before { content: '\e834'; } /* '' */
.icon-Sacola_com_cifrao:before { content: '\e835'; } /* '' */
.icon-Maos_entregando_chave:before { content: '\e836'; } /* '' */
.icon-Escrita:before { content: '\e837'; } /* '' */
.icon-Dinheiros:before { content: '\e838'; } /* '' */
.icon-Simbolo_seguranca_se:before { content: '\e83a'; } /* '' */
.icon-Mao_com_cifrao-1:before { content: '\e83b'; } /* '' */
.icon-Notas_de_dinheiro:before { content: '\e83c'; } /* '' */
.icon-Calculadora-2:before { content: '\e83d'; } /* '' */
.icon-Cel:before { content: '\e83e'; } /* '' */
.icon-Bike:before { content: '\e840'; } /* '' */
.icon-Celular_casa-1:before { content: '\e841'; } /* '' */
.icon-fundacao:before { content: '\e842'; } /* '' */
.icon-Bike_compartilhada:before { content: '\e843'; } /* '' */
.icon-Kit_eletro:before { content: '\e848'; } /* '' */
.icon-Bikes_compartilhadas:before { content: '\e84b'; } /* '' */
.icon-Carro_compartilhado:before { content: '\e84c'; } /* '' */
.icon-Chuveiro_a_gas:before { content: '\e84d'; } /* '' */
.icon-Acabamento_esp:before { content: '\e84e'; } /* '' */
.icon-Casa_padrao:before { content: '\e850'; } /* '' */
.icon-Grupo-pessoas:before { content: '\e851'; } /* '' */
.icon-Seta-direita:before { content: '\e852'; } /* '' */
.icon-Espaco_fogueira:before { content: '\e853'; } /* '' */
.icon-Armarios_planejados:before { content: '\e856'; } /* '' */
.icon-Acabamentos_especiais:before { content: '\e857'; } /* '' */
.icon-Armarios:before { content: '\e858'; } /* '' */
.icon-energia:before { content: '\e859'; } /* '' */
.icon-Descontos_exclusivos:before { content: '\e85a'; } /* '' */
.icon-Internet:before { content: '\e85b'; } /* '' */
.icon-Localizacao:before { content: '\e85c'; } /* '' */
.icon-Seta_preenchida_direita:before { content: '\e85d'; } /* '' */
.icon-Seta_preenchida_esquerda:before { content: '\e85e'; } /* '' */
.icon-Lazer:before { content: '\e85f'; } /* '' */
.icon-m2:before { content: '\e860'; } /* '' */
.icon-Dormitorio:before { content: '\e861'; } /* '' */
.icon-Check-1:before { content: '\e862'; } /* '' */
.icon-Infraestrutura_completa:before { content: '\e863'; } /* '' */
.icon-Loteamento_planejado:before { content: '\e864'; } /* '' */
.icon-localizacao-mapa:before { content: '\e867'; } /* '' */
.icon-X:before { content: '\e868'; } /* '' */
.icon-Pagamento_rapido:before { content: '\e869'; } /* '' */
.icon-Gerente_expert:before { content: '\e86a'; } /* '' */
.icon-Comissao_fixa:before { content: '\e86b'; } /* '' */
.icon-Comissao:before { content: '\e86c'; } /* '' */
.icon-Percentual_obra:before { content: '\e86d'; } /* '' */
.icon-Novo_contrato:before { content: '\e86e'; } /* '' */
.icon-Chave-1:before { content: '\e86f'; } /* '' */
.icon-FGTS:before { content: '\e870'; } /* '' */
.icon-Contrato_01:before { content: '\e871'; } /* '' */
.icon-Chave_3:before { content: '\e872'; } /* '' */
.icon-Checklist:before { content: '\e873'; } /* '' */
.icon-Simbolo_dinheiro:before { content: '\e874'; } /* '' */
.icon-Lupa:before { content: '\e875'; } /* '' */
.icon-Folha_Escrita:before { content: '\e876'; } /* '' */
.icon-Porquinho:before { content: '\e877'; } /* '' */
.icon-Documento:before { content: '\e878'; } /* '' */
.icon-Varinha:before { content: '\e879'; } /* '' */
.icon-Celular_casa:before { content: '\e87a'; } /* '' */
.icon-Dispositivo_celular:before { content: '\e87b'; } /* '' */
.icon-Espaco_crossfit:before { content: '\e87c'; } /* '' */
.icon-Espaco_praia:before { content: '\e87d'; } /* '' */
.icon-Quadra_de_beach_tennis:before { content: '\e87e'; } /* '' */
.icon-Carro:before { content: '\e87f'; } /* '' */
.icon-Sofa:before { content: '\e880'; } /* '' */
.icon-alert:before { content: '\e881'; } /* '' */
.icon-Quadrado_seta:before { content: '\e883'; } /* '' */
.icon-Coworking:before { content: '\e884'; } /* '' */
.icon-Servicos_class:before { content: '\e885'; } /* '' */
.icon-Folder:before { content: '\e887'; } /* '' */
.icon-Olho_fechado:before { content: '\e888'; } /* '' */
.icon-Galeria:before { content: '\e889'; } /* '' */
.icon-Predio:before { content: '\e88a'; } /* '' */
.icon-Ler_menos:before { content: '\e88b'; } /* '' */
.icon-Mais:before { content: '\e88c'; } /* '' */
.icon-Check:before { content: '\e88d'; } /* '' */
.icon-Preparacao_para_wi-fi_nas_areas_de_lazer:before { content: '\e88e'; } /* '' */
.icon-Previsao_para_medicao_de_agua_individualizada:before { content: '\e88f'; } /* '' */
.icon-Qualidade_ambiental:before { content: '\e890'; } /* '' */
.icon-Revestimento_das_areas_molhadas:before { content: '\e891'; } /* '' */
.icon-Revestimento_total_das_areas_molhadas:before { content: '\e892'; } /* '' */
.icon-Sacada:before { content: '\e893'; } /* '' */
.icon-Sacada_com_churrasqueira:before { content: '\e894'; } /* '' */
.icon-Sistema_de_seguranca_bio:before { content: '\e895'; } /* '' */
.icon-Sistema_de_seguranca_eco:before { content: '\e896'; } /* '' */
.icon-Tanque_em_marmore_sintetico:before { content: '\e897'; } /* '' */
.icon-Tomadas_usb:before { content: '\e898'; } /* '' */
.icon-Seguranca:before { content: '\e899'; } /* '' */
.icon-Varanda_gourmet:before { content: '\e89a'; } /* '' */
.icon-Alisar_nas_janelas:before { content: '\e89b'; } /* '' */
.icon-Area_de_lazer_para_todas_as_idades:before { content: '\e89c'; } /* '' */
.icon-Bancadas_do_banheiro_em_granito:before { content: '\e89d'; } /* '' */
.icon-Bancadas_em_granito_e_tanque_em_aco_inox:before { content: '\e89e'; } /* '' */
.icon-Bancadas_em_marmore_sintetico:before { content: '\e89f'; } /* '' */
.icon-Calculadora-1:before { content: '\e8a0'; } /* '' */
.icon-Bike_mrv:before { content: '\e8a1'; } /* '' */
.icon-Ceramica_cozinha_e_banheiro:before { content: '\e8a2'; } /* '' */
.icon-Coleta_seletiva:before { content: '\e8a3'; } /* '' */
.icon-Dispositivos_economizadores_de_agua:before { content: '\e8a4'; } /* '' */
.icon-Dispositivos_economizadores_de_energia:before { content: '\e8a5'; } /* '' */
.icon-Elevador:before { content: '\e8a6'; } /* '' */
.icon-Energia_solar_apartamentos:before { content: '\e8a7'; } /* '' */
.icon-Energia_solar_areas_comuns:before { content: '\e8a8'; } /* '' */
.icon-Forro_de_gesso_no_banheiro:before { content: '\e8a9'; } /* '' */
.icon-Garagem_coberta:before { content: '\e8aa'; } /* '' */
.icon-Janelas_especiais:before { content: '\e8ab'; } /* '' */
.icon-Lazer_equipado:before { content: '\e8ac'; } /* '' */
.icon-Medicao_de_agua_individualizada:before { content: '\e8ad'; } /* '' */
.icon-Novas_guaritas:before { content: '\e8ae'; } /* '' */
.icon-Pet_friendly:before { content: '\e8af'; } /* '' */
.icon-Piso_laminado_na_sala:before { content: '\e8b0'; } /* '' */
.icon-Piso_laminado_no_quarto:before { content: '\e8b1'; } /* '' */
.icon-Pomar:before { content: '\e8b2'; } /* '' */
.icon-Porcelanato:before { content: '\e8b3'; } /* '' */
.icon-Portas_especiais:before { content: '\e8b4'; } /* '' */
.icon-Lavanderia_compartilhada:before { content: '\e8b5'; } /* '' */
