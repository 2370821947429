@font-face {
  font-family: "mrv-icones";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/build/fonts/mrv-icons.eot?08072020");
  src: url("/build/fonts/mrv-icons.eot?08072020#iefix")
      format("embedded-opentype"),
    url("/build/fonts/mrv-icons.woff?08072020") format("woff"),
    url("/build/fonts/mrv-icons.ttf?08072020") format("truetype"),
    url("/build/fonts/mrv-icons.svg?08072020#header-footer") format("svg");
}

.breadcrumbs, .breadcrumbs.newBread {
    [class^="icon-"],
    [class*="icon-"] {
        &:before {
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    a {
        p.btn {
            font-size: 16px;
            text-transform: capitalize;
            width: 100%;

            @media screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }

    .icone {
        margin: -3px 0 0;

        .icon-casa {
            &:before {
                content: "\e812";
            }
        }

        i {
            &.font-mrv-icons {
                &:before {
                    font-family: "mrv-icones";
                }
            }

            &:before {
                width: 24px;
                font-size: 21px;

                @media screen and (max-width: 767px) {
                    font-size: 22px;
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    .seta {
        margin: -3px 1px 0 0;

        @media screen and (max-width: 767px) {
            margin: 3px 2px 0px;
        }
    }
}
